<template>
  <div class="soft">
    <div class="banner">
        <h1>{{ versionNum }}</h1>
    </div>
    <div class="soft-body">
      <!-- <div class="big-label">{{ versionNum }}</div> -->
      <div class="soft-table">
        <p class="el-icon-folder-opened title"> Version and Patch</p>
        <table>
          <thead>
            <tr>
              <th style="width: 60%;"><div>Firmware Name</div></th><th style="width: 10%;"><div>Size</div></th><th style="width: 15%;"><div>publication Date</div></th><th style="width: 15%; text-align: center;"><div>Download Firmware</div></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td>{{ item.softName }} &nbsp;<i v-show="item.isLock" class="el-icon-lock"></i></td><td>{{ item.size }} Mb</td><td>{{ item.publicationDate | dateFilter }}</td><td class="t-btn"><a @click="download(item)" class="el-icon-download"></a></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="soft-table" v-show="list2.length > 0">
        <p class="el-icon-folder-opened title"> Documentation</p>
        <table>
          <thead>
            <tr>
              <th style="width: 60%;"><div>Firmware Name</div></th><th style="width: 10%;"><div>Size</div></th><th style="width: 15%;"><div>publication Date</div></th><th style="width: 15%; text-align: center;"><div>Download Firmware</div></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list2" :key="index">
              <td>{{ item.softName }} &nbsp;<i v-show="item.isLock" class="el-icon-lock"></i></td><td>{{ item.size }} Mb</td><td>{{ item.publicationDate | dateFilter }}</td><td class="t-btn"><a @click="download(item)" class="el-icon-download"></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <download-file ref="down"></download-file> -->
  </div>
</template>

<script>
// import DownloadFile from '@/views/common/DownloadFile.vue';
import {MessageBox} from 'element-ui';
import {getFirmSoftsByInfo, addDownloadTimes} from '@/api/firmwareApi';
export default {
  name: 'software',
  // components: {
  //   DownloadFile
  // },
  data() {
    return {
      code: '',
      versionNum: '',
      list: [],
      list2: []
    }
  },
  mounted() {
    this.code = this.$route.params.code;
    this.getData()
  },
  methods: {
    async getData() {
     const {data: {data, isSuccess}} = await getFirmSoftsByInfo({'versionCode': this.code, 'email': this.$store.state.client.user.account})
     if (isSuccess) {
      this.versionNum = Object.keys(data)[0]
      this.list = data[this.versionNum].filter(item => item.category === 'soft')
      this.list2 = data[this.versionNum].filter(item => item.category === 'doc')
     }
    },
    toLogin() {
      this.$router.push({name: 'login'})
    },
    download(item) {
      if (!item.isLock) {
        // this.$refs.down.downloadFile(item.src, item.softName)
        addDownloadTimes(item)
        window.open(item.src)
      } else {
            const h = this.$createElement;
            // 文件上锁了，就另外处理
            MessageBox.alert('',{
                title: 'tips',
                message: h('div', null, [
                    h('p', null, [
                        h('span', null, 'To download the requested file(s),  you need to log in and contact '),
                        h('i', { style: 'color: #d2333c' }, 'website@holowits.com '),
                        h('span', null, 'to request download permission. ')
                    ]),
                    h('p', null, 'Please provide your account and the full name of the requested file(s), so we can respond to your request. We will review your request as soon as possible and notify you by email whether your download permission has been approved.'),
                    h('p', null, 'Thank you for your interest in our content.')
                ]),
                confirmButtonText: 'Login',
                cancelButtonText: 'Cancel',
            }).then(action => {
                this.toLogin()
            })
      }
    }
  },
  filters: {
    dateFilter(value) {
      return value ? value.split(' ')[0] : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.down-progress {
  width: 100vw;
  height: 100vh;
  background-color: #33333345;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .down-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 400px;
    height: 80px;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 10px;
    .title {
      margin-bottom: 10px;
      color: #c7000b;
    }
  }
  
}
.el-icon-lock {
    font-weight: bold;
    color: #d2333c;
}
.banner {
    height: 320px;
    background-image: url('/static/img/banner.8e08f679.jpg');
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.29);
        pointer-events: none;
    }
    h1 {
        text-align: left;
        width: 70%;
        margin: auto;
        // padding-left: 200px;
        font-weight: 600;
        color: #ffffff;
        z-index: 2;
    }
}
.soft-body {
  width: 70%;
  margin: auto;
  .big-label {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      padding-left: 10px;
  }
  .soft-table {
    margin-top: 20px;
    &:last-child {
      margin-bottom: 50px;
    }
    .title {
      font-size: 20px;
    }
  }
  .soft-table + .soft-table {
    margin-top: 50px;
  }
  table {
      width: 100%;
      margin-top: 8px;
      thead {
          height: 36px;
          background-color: #f0f0f0;
          th {
              margin: 0 10px;
              &:not(:first-child) {
                text-align: center;
              }
              &:not(:last-child) {
                div {
                  border-right: 1px solid rgba(128, 128, 128, 0.219);
                }  
              }
              div {
                padding: 0 10px;
              }
          }
      }
      tbody {
          tr {
              border-bottom: 1px solid rgba(128, 128, 128, 0.219);
              height: 36px;
              td {
                  padding: 0 10px;
                  &:not(:first-child) {
                    text-align: center;
                  }
              }
              .t-btn {
                text-align: center;
                a {
                  cursor: pointer;
                  color: #c7000b;
                }
                font-size: 20px;
              }
              .t-link {
                  border-bottom: 1px solid transparent;
                  cursor: pointer;
                  &:hover {
                      border-bottom: 1px solid #000;
                  }
              }
          }
      }
  }
}
</style>