<template>
  <div>
    <!-- pc -->
    <div class="pc">
      <div class="cont-bx ">
        <div class="cont-bx-top">
          <div><img src="@/assets/LOGO.svg" alt="" style="height: 21px; width: 155px;"></div>
          <div class="cont-bx-top_right">
            <div class="cont-bx-top_right-h2">Follow Us</div>
            <div class="cont-bx-top_right-img">
              <a href="https://www.youtube.com/channel/UCU0dH1w01gqpU87RCwT1BNA" target="_blank"><img src="../../assets/image/home/Youtube-fill 1.png" alt=""></a>
              <a href="https://twitter.com/HoloWitsHQ" target="_blank"><img src="../../assets/image/home/X.png" alt=""></a>
              <a href="https://www.instagram.com/holowits/" target="_blank"><img src="../../assets/image/home/instagram 1.png" alt=""></a>
              <a href="https://www.facebook.com/HoloWits-103231438933196/?ref=pages_you_manage" target="_blank"><img src="../../assets/image/home/Frame 20.png" alt=""></a>
            </div>
          </div>
        </div>
      </div>
      <!-- 线 -->
      <div class="xian"></div>
      <div class="cont-bx">
        <div class="cont-bx_bottun">
          <div class="cont-bx_bottun_left">© 2025 HOLOWITS TECHNOLOGIES PTE. LTD. All Rights Reserved.</div>
          <div class="cont-bx_bottun_right">
            <ul>
              <li><a style="color: #333333" href="/legal" target="_blank">Terms of use</a></li>
              <li>|</li>
              <li><a style="color: #333333" href="/privacy-policy" target="_blank">Privacy</a></li>
              <li>|</li>
              <li @click="toSiteMap">Sitemap</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div class="yido">
      <div class="footer-img">
        <a href="https://www.youtube.com/channel/UCU0dH1w01gqpU87RCwT1BNA"><img src="../../assets/image/yidoimg/Group 69.png" alt=""></a>
        <a href="https://twitter.com/HoloWitsHQ"><img src="../../assets/image/yidoimg/yidong_x.png" alt=""></a>
        <a href="https://www.instagram.com/holowits/"><img src="../../assets/image/yidoimg/Group 68.png" alt=""></a>
        <a href="https://www.facebook.com/HoloWits-103231438933196/?ref=pages_you_manage"><img src="../../assets/image/yidoimg/Group 66.png" alt=""></a>
      </div>
      <div class="line"></div>
      <div class="content">
        <div>
          <span><a style="color: #333333" href="/legal" target="_blank">Terms of use</a></span>
          <span class="s-line">|</span>
          <span><a style="color: #333333" href="/privacy-policy" target="_blank">Privacy</a></span>
          <span class="s-line">|</span>
          <span>Sitemap</span>
        </div>
        <div>© 2025 HOLOWITS TECHNOLOGIES PTE. LTD. All Rights Reserved.</div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      // Frame:
    }
  },
  methods:{
    toSiteMap(){
      let routeUrl = this.$router.resolve({
        path: `/sitemap`
      });
      window.open(routeUrl.href, '_blank');
    }
  }
};
</script>

<style scoped lang="less">
@media (max-width: 991px) {
  .pc {
    display: none;
  }
  .yido {
    background: #f1f1f1;
  }
  .footer-img {
    display: flex;
    justify-content: space-around;
    a {
      padding: 29/7.5vw;
      box-sizing: border-box;
      width: 25%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .line {
    display: inline-block;
    width: 80%;
    margin: 34/7.5vw auto;
    border-bottom: 1px solid #e0e0e0;
  }
  .content {
    text-align: center;
    .s-line {
      margin: 0 2vw;
    }
    div:nth-of-type(1) {
      margin-bottom: 34/7.5vw;
      font-size: 22/7.5vw;

      font-weight: 400;
      color: #333333;
      line-height: 26/7.5vw;
    }
    div:nth-of-type(2) {
      padding-bottom: 34/7.5vw;
      font-size: 18/7.5vw;
      font-weight: 400;
      color: #999999;
      line-height: 21/7.5vw;
    }
  }
}
@media (min-width: 991px) {
  .yido {
    display: none;
  }
  .pc {
    width: 100%;
    background: #f0f0f0;
  }
  .cont-bx {
    max-width: 1410px;
    width: auto;
    margin: 0 auto;
    // display: flex;
    .cont-bx-top {
      height: 100px;
      display: flex;
      justify-content: space-between;
      line-height: 50px;
      padding: 30px 0;
      .cont-bx-top_right {
        display: flex;
        .cont-bx-top_right-h2 {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
          line-height: 50px;
        }
        .cont-bx-top_right-img {
          display: flex;
          a {
            margin-left: 40px;
          }
        }
      }
    }
  }
  .xian {
    width: 100%;
    height: 0px;
    opacity: 1;
    border: 1px solid #dcdcdc;
  }
  .cont-bx_bottun {
    height: 60px;
    line-height: 60px;
    display: flex;
    justify-content: space-between;
    .cont-bx_bottun_left {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
    }
    .cont-bx_bottun_right {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      ul {
        display: flex;
        li {
          margin-left: 13px;
        }
        li:nth-child(odd) {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
