<template>
  <div class="customer-voice">
    <div class="title">
        <!-- <p>Contact HOLOWITS</p> -->
        <img src="https://resources.holowits.com/customer-voice/banner-log-960-200.jpg" style="width: 100%; height: 100%;" alt="">
    </div>
    <div style="padding: 20px;">
        <el-form ref="form" label-position="top" :model="form" :rules="rules" label-width="80px">
            <!-- <el-form-item label="Company" prop="company">
                <el-input v-model="form.company"></el-input>
            </el-form-item>
            <el-form-item label="Country" prop="country">
                <el-input v-model="form.country"></el-input>
            </el-form-item>
            <el-form-item label="Name" prop="name">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="Industry" prop="industry">
                <el-input v-model="form.industry"></el-input>
            </el-form-item>
            <el-form-item label="Email" prop="email">
                <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="Contact information" prop="contactInformation">
                <el-input v-model="form.contactInformation" placeholder="Phone Number, WhatsApp Number. "></el-input>
                <p style="font-size: 28px; margin-top: 40px; margin-bottom: 30px; color: #c0c4d6;">Upload Name Card, Badge</p>
            </el-form-item> -->
            <el-form-item label="Upload Name Card, Badge" prop="imageUrl" required>
                <el-upload class="avatar-uploader" :show-file-list="false" :headers="headers" action="/api/file/file/anyone/upload" :data="{...fileBaseData,bizType:'customer-voice/ac'}" :before-upload="beforeFileUpload" :on-success="handleFileSuccess">
                    <!-- <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar"> -->
                    <p v-if="form.imageUrl" style="font-size: 32px; color: #000000;">{{ form.imageUrl }}</p>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="Briefly outline your project requirements." prop="requirements">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 100}" v-model="form.requirements"></el-input>
            </el-form-item>
            <el-form-item label="" prop="hasAuthorization">
                <el-checkbox v-model="form.hasAuthorization">
                    I agree to receive information about products, solutions, services, and offerings from HOLOWITS and HOLOWITS authorized partners. I understand that I can unsubscribe at any time.
                </el-checkbox>
            </el-form-item>
            <el-form-item label="" prop="hasPrivacy">
                <el-checkbox v-model="form.hasPrivacy">I understand and agree to the use and transfer of my personal information by HOLOWITS according to HOLOWITS’ Privacy PolicyandTerms of Use.</el-checkbox>
            </el-form-item>
        </el-form>
        <div style="text-align: center; margin: 100px 0;">
            <el-button type="primary" round @click="onSubmit">Submit</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import axiosApi from '@/api/axiosApi'
export default {
name: '',
data() {
    return {
        form: {
            name: '',
            country: '',
            company: '',
            industry: '',
            email: '',
            contactInformation: '',
            imageUrl: '',
            requirements: '',
            hasAuthorization: false,
            hasPrivacy: false
        },
        imageUrl: '',
        rules: {
            // company: {required: true, message: 'This field is required.', trigger: 'blur'},
            // country: {required: true, message: 'This field is required.', trigger: 'blur'},
            // contactInformation: {required: true, message: 'This field is required.', trigger: 'blur'},
            requirements: {required: true, message: 'This field is required.', trigger: 'blur'},
            imageUrl: {required: true, message: 'This field is required.'}
        },
        isSuccess: false,
        headers: {},
        fileBaseData: {
            bucket: "hwtresources",
            storageType: "HUAWEI_OSS",
            hash: true
        },
        isUploaded: false
    }
},
created() {
    this.headers = {
        // token: 'Bearer ' + db.get('HOLOWITS_TOKEN'),
        tenant: 'aG9sb3dpdHM=',
        Authorization: `Basic ${Base64.encode(`${process.env.VUE_APP_CLIENT_ID}:${process.env.VUE_APP_CLIENT_SECRET}`)}`
    }
},
methods: {
    beforeFileUpload(file) {
        const FileExt = file.name.replace(/.+\./, '').toLowerCase()
        const imageExt = ['jpg', 'png', 'webp', 'jpeg'];
        if (!imageExt.includes(FileExt)) {
            alert('Format error, please upload a jpg or png file')
            return false;
        }
        this.isUploaded = false
        return true;
    },
    handleFileSuccess(res, file) {
        if (!res.isSuccess) {
            alert('System Error,Upload Fail');
            return;
        }
        this.form.imageUrl = res.data.url
        this.isUploaded = true
    },
    onSubmit() {
        this.$refs['form'].validate((valid) => {
            if (valid) {
                if (!this.isSuccess) {
                    if (this.form.imageUrl) {
                        if (this.isUploaded) {
                            this.seend()
                        } else {
                            this.$message.info('Uploading...')
                        }
                    } else {
                        alter('Please Upload Name Card.')
                    }
                } else {
                    this.$message({
                        showClose: true,
                        message: 'Do not repeat submission.',
                        type: 'error'
                    });
                }
            } else {
                alert('error submit!!');
                return false;
            }
        });
    },
    async seend() {
        const {data: {data, isSuccess}} = await axiosApi({
            method: 'POST',
            url: '/intserv/customer-voice/add',
            data: this.form
        })
        if (isSuccess) {
            this.$message({
                showClose: true,
                message: 'Seed Success',
                type: 'success'
            });
            this.isSuccess = true;
            alert('Submit Success!');
        }
    }
}
}
</script>

<style lang="less" scoped>
.customer-voice {
    // background-color: #fdfdfd;
    height: 100vh;
    .title {
        height: 130px;
        font-size: 48px;
        p {
            line-height: 200px;
            text-align: center;
        }
    }
    /deep/.el-form {
        .el-form-item {
            margin-bottom: 46px;
        }
        .el-form-item__label {
            font-size: 16px;
        }
        .el-form-item__error {
            font-size: 16px;
        }
        .el-input {
            font-size: 16px;
        }
        .el-input__inner {
            height: 80px;
            line-height: 16px;
        }
        .el-textarea {
            font-size: 16px;
            .el-textarea__inner {
                min-height: 240px !important;
                height: 240px !important;
            }
        }
        .el-checkbox {
            // .el-checkbox__inner {
            //     width: 34px;
            //     height: 34px;
            //     &::after {
            //         box-sizing: content-box;
            //         content: "";
            //         border: 5px solid #FFFFFF;
            //         border-left: 0;
            //         border-top: 0;
            //         height: 18px;
            //         left: 5px;
            //         position: absolute;
            //         top: 1px;
            //         // transform: rotate(45deg) scaleY(0);
            //         width: 13px;
            //         transition: transform 0.15s ease-in 0.05s;
            //         transform-origin: center;
            //     }
            // }
            .el-checkbox__label {
                font-size: 16px;
                line-height: 24px;
                width: 95%;
            }
        }

        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: calc(100vw - 40px);
            height: 70px;
            line-height: 70px;
            text-align: center;
        }
        .avatar {
            width: 100%;
            height: 100%;
            display: block;
        }
        .el-upload__input {
            display: none !important;
        }
    }

    /deep/.el-checkbox {
        span {
            vertical-align: top;
        }
        .el-checkbox__label {
            white-space: break-spaces;
            font-size: 18px;
        }
    }
    // .el-button {
    //     border-radius: 42px;
    //     padding: 25px 45px;
    //     font-size: 36px;
    // }
}

</style>