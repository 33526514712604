import axiosApi from '@/api/axiosApi';

export const getFirmInfo = () => {
    return axiosApi({
        url: '/intserv/firm/getFirmInfo',
        method: 'get'
    })
}

export const findVisionByPId = (id) => {
    return axiosApi({
        url: `/intserv/firm/findVisionByPId/${id}`,
        method: 'get'
    })
}

export const findSoftByCode = (code) => {
    return axiosApi({
        url: `/intserv/firm/findSoftByCode/${code}`,
        method: 'get'
    })
}

export const getFirmSoftsByInfo = (data) => {
    return axiosApi({
        url: `/intserv/firm/getFirmSoftsByInfo`,
        method: 'post',
        data
    })
}

export const addDownloadTimes = (data) => {
    return axiosApi({
        url: `/intserv/firm/addDownloadTimes`,
        method: 'post',
        data
    })
}